import styled from "styled-components";
import { flexBox } from "../../style/Div";

export const ModalBackground = styled.div`
  ${flexBox("column", "center", "center")}
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(10px);
  z-index: 5;
`;

export const ModalBody = styled.div`
  ${flexBox("column", "center", "space-between")}
  width: 80%;
  height: 80%;
  background-color: ${(props) => props.theme.box};
  border-radius: 20px;
  padding: 20px;
`;

export const Header = styled.div`
  ${flexBox("row", "center", "space-between")}
  width: 100%;
  height: 10%;
  > span {
    font-size: 12px;
  }
`;

export const Body = styled.div`
  ${flexBox("column", "center")}
  width: 100%;
  height: 80%;
  overflow: scroll;
  > div:nth-child(1) {
    font-size: 12px;
  }
`;

export const ProblemResult = styled.div<{ isCorrect?: boolean }>`
  ${flexBox("row", "center", "space-between")}
  position:relative;
  width: 100%;
  border-bottom: 1px solid black;
  ${(props) => !props.isCorrect && "color: red;"}
  > div {
    ${flexBox("column", "center", "center")}
    width: 20%;
    height: 30px;
  }
`;

export const Button = styled.button`
  background-color: ${(props) => props.theme.btn};
`;
