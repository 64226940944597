export const flexBox = (
  direction: "row" | "column",
  align?: string,
  justify?: string
) => `
  display: flex;
  flex-direction: ${direction};
  ${align ? `align-items: ${align};` : ""}
  ${justify ? `justify-content: ${justify};` : ""}
`;
