export const dark = {
  footer: "#393E46",
  btn: "rgba(0,0,0,0.3)",
  box: "#393E46",
  background: "#0F0E0E",
  text: "#fff",
};
export const light = {
  footer: "#DFD7BF",
  btn: "#A8A196",
  box: "#FFEADD",
  background: "#F5F5F5",
  text: "#000",
};
