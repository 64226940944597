import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  Switch,
} from "@mui/material";
import { arrayUnion, doc, setDoc } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { ReactSketchCanvasRef, ReactSketchCanvas } from "react-sketch-canvas";
import { db } from "../../../fbase";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { setTimeTaken, updateSolveInfo } from "../../../slice/TestSlice";
import { ResultModal } from "../../modal/ResultModal";
import * as S from "./Solve.style";

export const Solve = () => {
  const dispatch = useAppDispatch();
  const { testInfo, solveInfo, timeTaken } = useAppSelector(
    (state) => state.test
  );
  const { user, isDark } = useAppSelector((state) => state.user);
  const canvasRef = useRef<ReactSketchCanvasRef>(null);
  const [pointer, setPointer] = useState<string>("all");
  const [isEraseMode, setIsEraseMode] = useState<boolean>(false);
  const [problemNumber, setProblemNumber] = useState<number>(0);
  const [restTime, setRestTime] = useState<number>(1800);
  const [selected, setSelected] = useState<number>(0);
  const [startTime, setStartTime] = useState<number>(Date.now());
  const [isFinish, setIsFinish] = useState<boolean>(false);
  const [backgroundSize, setBackgroundSize] = useState<string>(
    localStorage.getItem("pacemaker-background-size") ?? "300"
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setRestTime((restTime) => restTime - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    setStartTime(Date.now());
  }, [problemNumber]);
  const handleClickPrevButton = async () => {
    const path = await canvasRef.current?.exportPaths();
    dispatch(
      updateSolveInfo({
        index: problemNumber,
        info: {
          selected: Number(selected),
          path: path,
          isCorrect: Number(selected) === testInfo[problemNumber]?.answer,
          timeTaken:
            selected === solveInfo[problemNumber]?.selected
              ? solveInfo[problemNumber]?.timeTaken
              : (solveInfo[problemNumber]?.timeTaken ?? 0) +
                Date.now() -
                startTime,
        },
      })
    );
    canvasRef.current?.clearCanvas();
    setSelected(solveInfo[problemNumber - 1]?.selected ?? 0);
    canvasRef.current?.loadPaths(solveInfo[problemNumber - 1]?.path ?? []);
    setProblemNumber((problemNumber) => problemNumber - 1);
  };
  const handleClickNextButton = async () => {
    const path = await canvasRef.current?.exportPaths();
    dispatch(
      updateSolveInfo({
        index: problemNumber,
        info: {
          selected: Number(selected),
          path: path,
          isCorrect: Number(selected) === testInfo[problemNumber]?.answer,
          timeTaken:
            selected === solveInfo[problemNumber]?.selected
              ? solveInfo[problemNumber]?.timeTaken ?? 0
              : (solveInfo[problemNumber]?.timeTaken ?? 0) +
                Date.now() -
                startTime,
        },
      })
    );
    canvasRef.current?.clearCanvas();
    setSelected(solveInfo[problemNumber + 1]?.selected ?? 0);
    canvasRef.current?.loadPaths(solveInfo[problemNumber + 1]?.path ?? []);
    setProblemNumber((problemNumber) => problemNumber + 1);
  };
  const handleClickFinishButton = async () => {
    await handleClickNextButton();
    dispatch(setTimeTaken(1800 - restTime));
    setIsFinish(true);
  };

  const handleClickSizeUp = () => {
    const newBackgroundSize = String(Number(backgroundSize) + 100);
    setBackgroundSize(newBackgroundSize);
    localStorage.setItem("pacemaker-background-size", newBackgroundSize);
  };
  const handleClickSizeDown = () => {
    const newBackgroundSize = String(Number(backgroundSize) - 100);
    setBackgroundSize(newBackgroundSize);
    localStorage.setItem("pacemaker-background-size", newBackgroundSize);
  };

  const handleChangeAnswer = (value: number) => {
    setSelected(value);
  };

  useEffect(() => {
    canvasRef.current?.eraseMode(isEraseMode);
  }, [isEraseMode]);

  return (
    <S.SolveBox>
      {(isFinish || restTime < 0) && (
        <ResultModal
          solveInfo={solveInfo}
          testInfo={testInfo}
          timeTaken={timeTaken}
        />
      )}
      <S.RestTime>
        <div>
          <span>남은 시간</span> {Math.floor(restTime / 60)} :{" "}
          {String(restTime % 60).padStart(2, "0")}
        </div>
        <FormControlLabel
          control={
            <Switch
              checked={pointer === "all"}
              onChange={() => {
                pointer === "all" ? setPointer("pen") : setPointer("all");
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="손가락으로 쓰기"
        />
      </S.RestTime>
      <S.CnavasWrapper
        problemNumber={testInfo[problemNumber]?.code}
        isDark={isDark}
        backgroundSize={backgroundSize + "px"}
      >
        <ReactSketchCanvas
          ref={canvasRef}
          height="calc(100vh - 205px)"
          strokeWidth={1}
          eraserWidth={30}
          strokeColor={isDark ? "white" : "black"}
          canvasColor="transparent"
          allowOnlyPointerType={pointer}
        />
      </S.CnavasWrapper>
      <S.ButtonsBox>
        <S.Btn
          variant="contained"
          disabled={problemNumber === 0}
          onClick={handleClickPrevButton}
        >
          이전 문제
        </S.Btn>
        <FormControl>
          <S.NumberBtns
            row
            onChange={(event: any) => handleChangeAnswer(event.target.value)}
            value={selected}
          >
            {[1, 2, 3, 4, 5].map((num) => {
              return (
                <FormControlLabel
                  value={num}
                  control={<Radio size="small" />}
                  label={num}
                  labelPlacement="top"
                />
              );
            })}
          </S.NumberBtns>
        </FormControl>
        {problemNumber < 19 ? (
          <S.Btn
            variant="contained"
            disabled={problemNumber === 19}
            onClick={handleClickNextButton}
          >
            다음 문제
          </S.Btn>
        ) : (
          <Button variant="contained" onClick={handleClickFinishButton}>
            제출
          </Button>
        )}
      </S.ButtonsBox>
      <S.ButtonsBox>
        <S.Btn variant="contained" onClick={handleClickSizeDown}>
          문제 줄이기
        </S.Btn>
        <S.Btn variant="contained" onClick={handleClickSizeUp}>
          문제 키우기
        </S.Btn>
      </S.ButtonsBox>
      <S.Btn
        onClick={() => {
          setIsEraseMode((isEraseMode) => !isEraseMode);
        }}
      >
        {isEraseMode ? "지우개" : "펜"}
      </S.Btn>
    </S.SolveBox>
  );
};
