import { useEffect } from "react";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { TestPreview } from "../../test/TestPreview";
import * as S from "./Test.style";

export const Test = () => {
  const { testList } = useAppSelector((state) => state.test);
  const kice = testList
    .filter((test) => [6, 9, 11].includes(test % 100))
    .map((test) => {
      return { test: test };
    });
  const notKice = testList
    .filter((test) => [3, 4, 7, 10].includes(test % 100))
    .map((test) => {
      return { test: test };
    });
  return (
    <S.TestBox>
      <h1>기출문제</h1>
      <h3>평가원</h3>
      <TestPreview testList={kice} isSolved={false} />
      <h3>교육청</h3>
      <TestPreview testList={notKice} isSolved={false} />
    </S.TestBox>
  );
};
