import { RadioGroup, Button } from "@mui/material";
import styled, { css } from "styled-components";
import { flexBox } from "../../../style/Div";

export const SolveBox = styled.div`
  ${flexBox("column", "center")}
  height: calc(100%);
  overflow: visible;
  > button {
    position: absolute;
    top: 50px;
    right: 30px;
    width: fit-content;
  }
`;

export const RestTime = styled.div`
  ${flexBox("row", "center", "space-between")}
  width: 100%;
  height: 30px;
`;

export const CnavasWrapper = styled.div<{
  problemNumber: string;
  isDark: boolean;
  backgroundSize?: string;
}>`
  width: 100%;
  height: calc(100% - 90px);
  ${(props) =>
    props.isDark
      ? css`
          background-image: url(https://storage.googleapis.com/pacemaker/pacemaker-chem1-img/${props.problemNumber}-dark.jpg);
        `
      : css`
          background-image: url(https://storage.googleapis.com/pacemaker/pacemaker-chem1-img/${props.problemNumber}.jpg);
        `}
  background-size: ${(props) => props.backgroundSize ?? "300px"};
  background-repeat: no-repeat;
  background-position: 10px 10px;

  > div {
    border: 0.0625rem solid #9c9c9c;
    border-radius: 0.25rem;
    box-sizing: border-box;
    height: calc(100% - 50px);
  }
`;

export const ButtonsBox = styled.div`
  ${flexBox("row", "center", "space-between")}
  height: 60px;
  width: 100%;
  > div {
    width: calc(100% - 160px);
  }
`;

export const Btn = styled(Button)`
  width: 80px;
  font-size: 10px;
  background-color: ${(props) => props.theme.btn};
  &:hover {
    background-color: ${(props) => props.theme.btn};
  }
`;

export const NumberBtns = styled(RadioGroup)`
  ${flexBox("row", "center", "space-between")}
  height: 100%;
  width: 100%;
  > label {
    margin: 0px;
    width: 15%;
    margin-bottom: 20px;
    > span {
      padding: 3px;
      margin-bottom: -10px;
    }
  }
`;
