import { getAuth, signOut } from "firebase/auth";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";

import * as S from "./Profile.style";
import { TestPreview } from "../../test/TestPreview";
import { useEffect } from "react";
import { getSolved, setIsDark } from "../../../slice/UserSlice";
import { FormControlLabel, Switch } from "@mui/material";

export const Profile = () => {
  const dispatch = useAppDispatch();
  const { user, goalScore, solved, isDark } = useAppSelector(
    (state) => state.user
  );
  const handleClickSignOutBtn = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        console.log("sign out");
      })
      .catch((err) => console.log("err :", err));
  };
  const getSolvedTestList = () => {
    return solved?.map((test) => {
      let score = 0;
      for (let i = 0; i < 20; i++) {
        if (test.solveInfo[i]?.isCorrect) {
          score += test.testInfo[i]?.points;
        }
      }
      return {
        test: Number(test.solveCode.slice(0, 4)),
        score: score,
        time: test.timeTaken,
      };
    });
  };

  useEffect(() => {
    dispatch(getSolved(user.uid));
  }, [user.uid]);
  return (
    <S.ProfileBox>
      <S.MyProfileBox>
        <img src={user.photoURL} alt="profile" />
        <div>
          <FormControlLabel
            control={
              <Switch
                checked={isDark}
                onChange={() => {
                  if (isDark) {
                    localStorage.removeItem("pacemaker-isDark");
                  } else {
                    localStorage.setItem("pacemaker-isDark", "true");
                  }
                  dispatch(setIsDark());
                }}
              />
            }
            label="다크모드"
            labelPlacement="top"
          />
          <div onClick={handleClickSignOutBtn}>로그아웃</div>
          <span>이름 : {user.displayName}</span>
          <span>목표 점수 : {goalScore}점</span>
        </div>
      </S.MyProfileBox>
      <S.SolvedTestBox>
        <div>풀은 시험지</div>
        <TestPreview
          testList={getSolvedTestList()}
          isSolved={true}
          solved={solved}
        />
      </S.SolvedTestBox>
    </S.ProfileBox>
  );
};
