import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../fbase";

export interface ISolved {
  solveCode: string;
  testInfo: { code: string; answer: number; points: number }[];
  solveInfo: {
    selected: number;
    path: any;
    isCorrect: boolean;
    timeTaken: number;
  }[];
  timeTaken: number;
}

interface IState {
  user: { uid: string; displayName: string; photoURL: string };
  isDark: boolean;
  goalScore: number;
  solved: ISolved[];
}

const initialState: IState = {
  user: { uid: "default", displayName: "", photoURL: "" },
  isDark: false,
  goalScore: 50,
  solved: [],
};

export const getSolved = createAsyncThunk("getSolved", async (uid: string) => {
  const docRef = doc(db, "users", uid);
  const result = await getDoc(docRef);
  const solved = result.data()?.solved;
  return solved;
});

export const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setIsDark: (state) => {
      state.isDark = localStorage.getItem("pacemaker-isDark") ? true : false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSolved.fulfilled, (state, action) => {
      state.solved = action.payload;
    });
  },
});
export const { setUser, setIsDark } = userSlice.actions;
export default userSlice.reducer;
