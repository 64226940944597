import { arrayUnion, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../fbase";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { getSolved } from "../../slice/UserSlice";
import * as S from "./ResultModal.style";

interface Props {
  solveInfo: {
    selected: number;
    path: any;
    isCorrect: boolean;
    timeTaken: number;
  }[];
  testInfo: { code: string; answer: number; points: number }[];
  timeTaken: number;
  isSolved?: boolean;
  closeModal?: () => void;
}

export const ResultModal = ({
  solveInfo,
  testInfo,
  timeTaken,
  isSolved,
  closeModal,
}: Props) => {
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const testCode = testInfo[0].code.slice(0, 4);
  const getTitle = () => {
    if (["06", "09", "11"].includes(testCode.slice(2, 4))) {
      return `${Number(testCode.slice(0, 2)) - 1}년 ${Number(
        testCode.slice(2, 4)
      )}월 모의고사`;
    } else {
      return `${testCode.slice(0, 2)}년 ${Number(
        testCode.slice(2, 4)
      )}월 모의고사`;
    }
  };
  const getScore = () => {
    let score = 0;
    for (let i = 0; i < 20; i++) {
      if (solveInfo[i]?.isCorrect) {
        score += testInfo[i].points;
      }
    }
    return score;
  };
  const handleClickBtn = () => {
    if (isSolved && closeModal) {
      closeModal();
    } else {
      navigate("/test");
    }
  };

  useEffect(() => {
    const upload = async () => {
      const solveCode = testInfo[0].code.slice(0, 4) + Date.now();
      await setDoc(
        doc(db, "users", user.uid),
        {
          solved: arrayUnion({
            solveCode: solveCode,
            testInfo: testInfo,
            solveInfo: solveInfo,
            timeTaken: timeTaken,
          }),
        },
        { merge: true }
      );
      const uploadTest = solveInfo.map((solve, i) => {
        updateDoc(doc(db, "problems", testInfo[i].code), {
          solved: arrayUnion({
            uid: user.uid,
            timeTaken: solve.timeTaken,
          }),
        });
      });
      Promise.all(uploadTest);
    };
    if (!isSolved) {
      upload();
    }
  }, []);

  return (
    <S.ModalBackground>
      <S.ModalBody>
        <S.Header>
          <span>
            걸린 시간:{Math.floor(timeTaken / 60)}:
            {String(timeTaken % 60).padStart(2, "0")}{" "}
          </span>
          <span>{getTitle()}</span>
          <span>점수: {getScore()}점</span>
        </S.Header>
        <S.Body>
          <S.ProblemResult>
            <div>문항 코드</div>
            <div>선택지</div>
            <div>정답</div>
            <div>점수</div>
            <div>걸린 시간</div>
          </S.ProblemResult>
          {testInfo.map((p, i) => {
            return (
              <S.ProblemResult
                key={p?.code}
                isCorrect={solveInfo[i]?.selected === p?.answer}
              >
                <div>{p?.code}</div>
                <div>{solveInfo[i]?.selected}번</div>
                <div>{p?.answer}번</div>
                <div>{p?.points}점</div>
                <div>{(solveInfo[i]?.timeTaken / 1000).toFixed(2)}초</div>
              </S.ProblemResult>
            );
          })}
        </S.Body>
        <S.Button onClick={handleClickBtn}>돌아가기</S.Button>
      </S.ModalBody>
    </S.ModalBackground>
  );
};
