import * as S from "./Auth.style";
import {
  db,
  getAuth,
  GoogleAuthProvider,
  provider,
  signInWithPopup,
} from "../../../fbase";
import { setDoc, doc } from "@firebase/firestore";

export const Auth = () => {
  const auth = getAuth();
  const signInWithGoogle = async () => {
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const user = result.user;
        if (user.metadata.creationTime === user.metadata.lastSignInTime) {
          try {
            await setDoc(doc(db, "users", user.uid), {
              uid: user.uid,
              solved: [],
              incorrect: [],
            });
          } catch (e) {
            console.error(e);
          }
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.email;
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.log(errorCode, errorMessage, email, credential);
      });
  };

  const handleClickGoogleLoginBtn = () => {
    signInWithGoogle();
  };

  return (
    <S.AuthBox>
      <S.DiscriptionBox>
        안녕하세요. 수능 대비 문제풀이 서비스 pacemaker입니다.
        <br />
        <br />
        pacemaker에서는 학생들의 풀이 정보를 바탕으로
        <br />
        나의 유형별, 단원별 현 위치를 파악할 수 있습니다.
        <br />
        이를 위해 풀이 정보를 수집하고 있으며
        <br />
        로그인 후에 서비스를 이용할 수 있습니다.
        <br />
        <br />
        11월 16일 좋은 결과 있길 기원합니다!
      </S.DiscriptionBox>
      <S.GoogleLoginBtn onClick={handleClickGoogleLoginBtn}>
        <i />
        구글 아이디 로그인
      </S.GoogleLoginBtn>
    </S.AuthBox>
  );
};
