import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../fbase";

interface IState {
  testList: number[];
  testInfo: { code: string; answer: number; points: number }[];
  solveInfo: {
    selected: number;
    path: any;
    isCorrect: boolean;
    timeTaken: number;
  }[];
  timeTaken: number;
}

const initialState: IState = {
  testList: [],
  testInfo: [],
  solveInfo: [],
  timeTaken: 1800,
};

export const getTestList = createAsyncThunk("getTestList", async () => {
  const docRef = doc(db, "tests", "tests");
  const result = await getDoc(docRef);
  return result.data()?.testList;
});

export const getTestInfo = createAsyncThunk(
  "getTestInfo",
  async (args: { test: number }) => {
    const { test } = args;
    const problemList = Array.from(
      { length: 20 },
      (v, i) => test + String(i + 1).padStart(2, "0")
    );
    const testInfo = await Promise.all(
      problemList.map(async (p) => {
        const docRef = doc(db, "problems", p);
        const result = await getDoc(docRef);
        return result.data();
      })
    );
    return testInfo.map((p: any, i: number) => {
      return { code: problemList[i], answer: p.answer, points: p.points };
    });
  }
);

export const testSlice = createSlice({
  name: "testSlice",
  initialState,
  reducers: {
    updateSolveInfo: (state, action) => {
      state.solveInfo[action.payload.index] = action.payload.info;
    },
    resetSolveInfo: (state) => {
      state.solveInfo = [];
    },
    setTimeTaken: (state, action) => {
      state.timeTaken = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTestList.fulfilled, (state, action) => {
      state.testList = action.payload;
    });
    builder.addCase(getTestInfo.fulfilled, (state, action) => {
      state.testInfo = action.payload;
    });
  },
});
export const { updateSolveInfo, resetSolveInfo, setTimeTaken } =
  testSlice.actions;
export default testSlice.reducer;
