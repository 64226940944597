import styled from "styled-components";
import { flexBox } from "../../style/Div";

export const PreviewBox = styled.div`
  width: 500px;
  > div {
    > div {
      width: 250px;
    }
  }
`;

export const TestBox = styled.div`
  ${flexBox("column", "center")};
  background-color: ${(props) => props.theme.box};
  margin: 20px;
  padding: 20px;
  width: 200px;
  height: 200px;
  border-radius: 20px;

  > div:nth-child(1) {
    font-size: 26px;
  }
  > div:nth-child(2) {
    font-size: 64px;
    line-height: 100px;
  }
`;
