import { EffectCoverflow, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import * as S from "./TestPreview.style";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { getTestInfo } from "../../slice/TestSlice";
import { useNavigate } from "react-router-dom";
import { ISolved } from "../../slice/UserSlice";
import { ResultModal } from "../modal/ResultModal";
import { useState } from "react";

interface Props {
  testList: { test: number; score?: number; time?: number }[];
  isSolved: boolean;
  solved?: ISolved[];
}

export const TestPreview = ({ testList, isSolved, solved }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [indexOfTest, setIndexOfTest] = useState<number>(0);
  const [showResultModal, setShowResultModal] = useState<boolean>(false);
  const getDate = (timestamp: number) => {
    return new Date(timestamp).toLocaleDateString();
  };
  const handleClickTest = (test: number, index: number) => {
    if (!isSolved) {
      dispatch(getTestInfo({ test: test })).then((res: any) =>
        navigate("/solve")
      );
    } else {
      setIndexOfTest(index);
      setShowResultModal(true);
    }
  };
  return (
    <S.PreviewBox>
      {showResultModal && solved && (
        <ResultModal
          solveInfo={solved[indexOfTest]?.solveInfo}
          testInfo={solved[indexOfTest]?.testInfo}
          timeTaken={solved[indexOfTest]?.timeTaken}
          isSolved={isSolved}
          closeModal={() => setShowResultModal?.(false)}
        />
      )}

      <Swiper
        effect="coverflow"
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: -20,
          stretch: 0,
          depth: 100,
          modifier: 2,
          slideShadows: false,
        }}
        pagination={true}
        modules={[EffectCoverflow, Pagination]}
      >
        {testList?.map((test, index) => {
          const isKice = [6, 9, 11].includes(test.test % 100);
          const year = isKice
            ? `20${Math.floor(test.test / 100) - 1}년`
            : `20${Math.floor(test.test / 100)}년`;

          const min = Math.floor((test.time ?? 0) / 60);
          const sec = (test.time ?? 0) % 60;
          return (
            <SwiperSlide>
              <S.TestBox onClick={() => handleClickTest(test.test, index)}>
                <div>{year}</div>
                <div>
                  {test.test % 100}
                  <span>월</span>
                </div>
                {test.score && solved && (
                  <>
                    <div>점수: {test.score}점</div>
                    <div>
                      걸린 시간: {min}분 {sec}초
                    </div>
                    <div>
                      푼 날짜:
                      {getDate(Number(solved[index].solveCode.slice(4)))}
                    </div>
                  </>
                )}
              </S.TestBox>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </S.PreviewBox>
  );
};
