import styled from "styled-components";
import { flexBox } from "../../../style/Div";

export const ProfileBox = styled.div`
  ${flexBox("column", "center")}
  height: calc(100% - 56px);
  gap: 50px;
`;
export const MyProfileBox = styled.div`
  ${flexBox("row", "center", "space-between")}
  position: relative;
  padding: 20px;
  background-color: ${(props) => props.theme.box};
  width: 80%;
  height: 200px;
  border-radius: 10px;
  > img {
    border-radius: 100%;
  }
  > div {
    ${flexBox("column", "", "flex-start")}
    gap: 5px;
    width: 30%;
    > div:nth-child(1) {
      position: absolute;
      top: 10px;
      left: 10px;
      padding: 5px 10px;
      border-radius: 8px;
      cursor: pointer;
      background-color: ${(props) => props.theme.btn};
    }
    > div {
      padding: 5px 10px;
      border-radius: 8px;
      width: fit-content;
      cursor: pointer;
      background-color: ${(props) => props.theme.btn};
    }
  }
`;

export const SolvedTestBox = styled.div`
  text-align: center;
`;
