import styled from "styled-components";

export const App = styled.div`
  width: calc(100vw - 40px);
  height: calc(100vh - 96px);
  padding: 20px;
  background-color: ${(props) => props.theme.background};
  color: ${(props) => props.theme.text};
  overflow: scroll;
`;
