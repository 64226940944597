import { BottomNavigation } from "@mui/material";
import styled from "styled-components";

export const FooterBox = styled(BottomNavigation)`
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background-color: ${(props) => props.theme.footer};
  z-index: 5;
`;
