import * as S from "./Footer.style";
import { Link } from "react-router-dom";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";

import { useState } from "react";

function Footer() {
  const [value, setValue] = useState<string>(window.location.pathname);
  return (
    <S.FooterBox
      showLabels
      value={value}
      onChange={(event: any, newValue: string) => {
        setValue(newValue);
      }}
    >
      <BottomNavigationAction
        component={Link}
        to="/profile"
        label="프로필"
        icon={<AccountCircleOutlinedIcon />}
      />
      <BottomNavigationAction
        component={Link}
        to="/test"
        label="문제 풀기"
        icon={<QuizOutlinedIcon />}
      />
      <BottomNavigationAction
        component={Link}
        to="/community"
        label="공부"
        icon={<ForumOutlinedIcon />}
      />
    </S.FooterBox>
  );
}

export default Footer;
