import * as S from "./App.style";
import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Footer from "./components/footer/Footer";
import { Auth } from "./components/pages/auth/Auth";
import { Community } from "./components/pages/community/Community";
import { Home } from "./components/pages/home/Home";
import { Profile } from "./components/pages/profile/Profile";
import { Solve } from "./components/pages/solve/Solve";
import { Test } from "./components/pages/test/Test";
import { useAppDispatch, useAppSelector } from "./hooks/reduxHooks";
import { dark, light } from "./style/theme";
import { getAuth } from "firebase/auth";
import { setIsDark, setUser } from "./slice/UserSlice";
import { getTestList, resetSolveInfo } from "./slice/TestSlice";

const mainPathList = ["auth", "profile", "test", "community", "home", "solve"];

function App() {
  const dispatch = useAppDispatch();
  const { user, isDark } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();
  const theme = isDark ? dark : light;
  const auth = getAuth();

  useEffect(() => {
    auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        dispatch(
          setUser({
            uid: currentUser.uid,
            displayName: currentUser.displayName,
            photoURL: currentUser.photoURL,
          })
        );
      } else {
        dispatch(
          setUser({
            uid: "",
            displayName: "",
            photoURL: "",
          })
        );
      }
      dispatch(getTestList());
    });
    dispatch(setIsDark());
  }, []);

  useEffect(() => {
    if (location.pathname !== "/solve") {
      dispatch(resetSolveInfo());
    }
    if (user.uid === "") {
      navigate("/auth");
      return;
    } else {
      if (location.pathname === "/auth") {
        navigate("/home");
        return;
      }
      if (location.pathname === "/") {
        navigate("/home");
        return;
      }
      if (!mainPathList.includes(location.pathname.split("/")[1])) {
        navigate("/home");
        return;
      }
    }
  }, [location.pathname, user]);

  return (
    <ThemeProvider theme={theme}>
      <S.App className="App">
        <div className="body">
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/auth" element={<Auth />} />
            <Route path="/profile/*" element={<Profile />} />
            <Route path="/test/*" element={<Test />} />
            <Route path="/community/*" element={<Community />} />
            <Route path="/solve/*" element={<Solve />} />
          </Routes>
        </div>
        {location.pathname !== "/solve" && <Footer />}
      </S.App>
    </ThemeProvider>
  );
}

export default App;
