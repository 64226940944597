import { useEffect, useState } from "react";
import * as S from "./Home.style";

export const Home = () => {
  const [restTime, setRestTime] = useState({
    day: 0,
    hour: 0,
    min: 0,
    sec: 0,
    meal: 0,
  });
  const dDay = 1731538800000;
  useEffect(() => {
    const interval = setInterval(() => {
      const now = Date.now();
      let diff = dDay - now;
      const diffDay = Math.floor(diff / (1000 * 60 * 60 * 24));
      diff = diff % (1000 * 60 * 60 * 24);
      const diffHour = Math.floor(diff / (1000 * 60 * 60));
      diff = diff % (1000 * 60 * 60);
      const diffMin = Math.floor(diff / (1000 * 60));
      diff = diff % (1000 * 60);
      const diffSec = Math.floor(diff / 1000);
      setRestTime({
        day: diffDay,
        hour: diffHour,
        min: diffMin,
        sec: diffSec,
        meal: diffDay * 3 + Math.floor(diffHour / 8),
      });
      return () => clearInterval(interval);
    }, 1000);
  }, []);

  return (
    <S.HomeBox>
      <h1>D-DAY</h1>
      <div>
        {restTime.day +
          "일 " +
          restTime.hour +
          "시간 " +
          restTime.min +
          "분 " +
          restTime.sec +
          "초"}
      </div>
      <div>{"밥 " + restTime.meal + "공기 먹으면 수능"}</div>
    </S.HomeBox>
  );
};
