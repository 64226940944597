import styled from "styled-components";
import GoogleIcon from "../../../assets/google-icon.png";
import { flexBox } from "../../../style/Div";

export const AuthBox = styled.div`
  width: 100%;
  height: 100%;
  ${flexBox("column", "center", "center")}
  gap:50px;
`;

export const DiscriptionBox = styled.div`
  width: 50%;
  background-color: ${(props) => props.theme.box};
  padding: 20px;
  border-radius: 8px;
`;

export const GoogleLoginBtn = styled.div`
  ${flexBox("row", "center", "center")}
  gap: 10px;
  cursor: pointer;
  padding: 10px 16px;
  width: fit-content;
  border-radius: 10px;
  background-color: ${(props) => props.theme.btn};
  > i {
    width: 30px;
    height: 30px;
    background-image: url(${GoogleIcon});
    display: inline-block;
    cursor: pointer;
    background-size: contain;
  }
`;
