import styled from "styled-components";
import { flexBox } from "../../../style/Div";

export const CommunityBox = styled.div`
  ${flexBox("column", "center", "center")}
  gap: 30px;
  height: calc(100% - 56px);
  > div {
    background: ${(props) => props.theme.box};
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
  }
`;
