import * as S from "./Community.style";
export const Community = () => {
  const handleClickKakaoBtn = () => {
    window.open("https://open.kakao.com/o/ggCZxO2b");
  };
  const handleClickPacemakerBtn = () => {
    window.open("https://open.kakao.com/o/scktESwf");
  };
  return (
    <S.CommunityBox>
      <div onClick={handleClickKakaoBtn}>화학1 카카오톡 오픈채팅방</div>
      <div onClick={handleClickPacemakerBtn}>pacemaker 문의, 요청사항</div>
    </S.CommunityBox>
  );
};
