import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    body{
        margin:0;
        padding:0;
    }
    .App{
        width:calc(100vw - 40px);
        height:calc(100vh - 40px);
        padding: 20px;
    }
    .body{
        width:100%;
        height:calc(100% - 46px);
        overflow: scroll;
    }
`;
export default GlobalStyle;
